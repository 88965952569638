$color-navbar: #333333;
$color-resource-task-planned-confirmed: #d8ebcf;
$color-resource-task-emergency: #e6b0ae;
$color-resource-task-pending: #f0f0f0;

$color-box-background: #f0f0f0;
$color-background: #ffffff;

$color-text-light: #ffffff;
$color-text-dark: #000000;
$color-text-label: #2b2b2b;
$color-text-muted: #aaaaaa;

$glbGreen: #5d9528;
$glbGreenLight: #f1f9ea;
$primaryColor: #b6d7a8;
$actionColor: #757575;
$font-family: Montserrat, 'Helvetica Neue', sans-serif;

$color-wp-running: #d8ebcf;
$color-wp-paused: #ebcfcf;
$color-wp-completed: #cad2eb;

$color-sidebar-bg: #e5e5e5;
$color-sidebar: #757575;

$grid-bg-color: #f3f3f3;

$tablet-screen-size: 1024px;
$grid-height: 350px;
$grid-control-bar-height: 50px;
$navbar-height: 115px;
