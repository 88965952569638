/* You can add global styles to this file, and also import other style files */
// @import "@angular/material/prebuilt-themes/indigo-pink.css";
@import '~fontsource-montserrat/index.css';
@import '~fontsource-montserrat/600-normal.css';
@import '~fontsource-montserrat/500-normal.css';
@import 'variables';
@import '@angular/material/theming';
@import '../node_modules/ag-grid-community/src/styles/ag-grid.scss';
@import '../node_modules/ag-grid-community/src/styles/ag-theme-material/sass/ag-theme-material-mixin.scss';
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// created with http://mcg.mbitson.com/
$md-resourceplanner-accent: (
  50: #f6faf5,
  100: #e9f3e5,
  200: #dbebd4,
  300: #cce3c2,
  400: #c1ddb5,
  500: #b6d7a8,
  600: #afd3a0,
  700: #a6cd97,
  800: #9ec78d,
  900: #8ebe7d,
  A100: #ffffff,
  A200: #ffffff,
  A400: #e7ffde,
  A700: #d4ffc4,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
$md-resourceplanner-warn: (
  50: #fdf5f5,
  100: #fbe6e6,
  200: #f8d6d6,
  300: #f5c6c6,
  400: #f2b9b9,
  500: #f0adad,
  600: #eea6a6,
  700: #ec9c9c,
  800: #e99393,
  900: #e58383,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffffff,
  A700: #fff0f0,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
$md-resourceplanner-primary: (
  50: #ecf2e5,
  100: #cedfbf,
  200: #aeca94,
  300: #8eb569,
  400: #75a548,
  500: #5d9528,
  600: #558d24,
  700: #4b821e,
  800: #417818,
  900: #30670f,
  A100: #bdff9c,
  A200: #9bff69,
  A400: #79ff36,
  A700: #68ff1c,
  contrast: (
    50: #ffffff,
    100: #ffffff,
    200: #ffffff,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$primary: mat-palette($md-resourceplanner-primary);
$accent: mat-palette($md-resourceplanner-accent);
$warn: mat-palette($md-resourceplanner-warn);
// Create the theme object (a Sass map containing all of the palettes).
$candy-app-theme: mat-light-theme($primary, $accent, $warn);
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($candy-app-theme);

$custom-typography: mat-typography-config(
  $font-family: 'Montserrat, monospace',
);
@include angular-material-typography($custom-typography);

@import '@angular/cdk/overlay-prebuilt.css';

.mat-tab-link {
  color: $color-text-light;
}

.mat-checkbox-label {
  color: $color-text-label;
}

.mat-button.mat-primary,
.mat-icon-button.mat-primary,
.mat-stroked-button.mat-primary {
  background: $glbGreenLight;
}

.mat-button.mat-accent,
.mat-icon-button.mat-accent,
.mat-stroked-button.mat-accent {
  background: white;
  border: solid 1px $glbGreen;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: $color-text-label;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  padding: 0 0.75em 0 0.75em !important;
}

.mat-form-field-wrapper {
  padding: 0.75em 0;
}

.mat-form-field-underline {
  bottom: 0.75em;
}

.mat-select-min-line {
  color: $glbGreen;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Montserrat, 'Helvetica Neue', sans-serif;
}

nav {
  background-color: $color-navbar;
}

.ag-theme-material {
  @include ag-theme-material();
}

.project-grid.ag-theme-material {
  .ag-header,
  .ag-row,
  .ag-center-cols-viewport {
    @apply bg-gray-100 w-full;
  }
}

app-planning-grid {
  .scrollable-content {
    height: unset !important;
    max-height: unset !important;
    min-height: 100vh !important;
  }
}

.mat-icon {
  color: $actionColor;
}

.ag-header-cell-text {
  color: $color-text-label;
  font-size: 14px;
  font-weight: 600;
}

.ag-theme-material {
  font-family: $font-family;

  .ag-header-row {
    font-weight: normal;
  }

  .ag-row {
    color: $color-text-dark;
  }
}

// https://stackoverflow.com/questions/35578083/what-is-the-equivalent-of-ngshow-and-nghide-in-angular-2
[hidden] {
  display: none !important;
}

.snack-bar-error {
  background-color: red;
  color: white;
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: none;
}

.mat-fab.white {
  background-color: white;
}

// add this class to a mat-form-field to keep the text color of an input readable if the element is disabled
.disabled-visibility {
  .mat-input-element:disabled {
    color: rgba(0, 0, 0, 0.87);
  }
}

.td-dialog-actions {
  .mat-button.mat-accent {
    color: black;
    background-color: $glbGreen;
  }

  button {
    text-transform: none !important;
    padding-left: 14px !important;
    padding-right: 14px !important;
  }
}

.borderless-modal {
  mat-dialog-container {
    padding: 0;
  }
}

.workload-tooltip {
  font-size: 1.1em;
}
